const localesMap: Record<string, string> = {
  en_GB: 'en_GB',
  en_IE: 'en_IE',
  fr_FR: 'fr',
  fr_CH: 'fr_CH',
  es_ES: 'es',
  it_IT: 'it',
  de_CH: 'de_CH',
  de_AT: 'de_AT',
  de_DE: 'de',
  lt_LT: 'lt',
  en_BE: 'en_BE',
  fr_BE: 'fr_BE',
  nl_BE: 'nl_BE',
  en_NL: 'en_NL',
  nl_NL: 'nl_NL',
  pt_PT: 'pt_PT',
  el_GR: 'el',
  it_CH: 'it_CH',
};

export const getSalesforceLanguage = (locale: string) => {
  return localesMap[locale] || 'en_GB';
};
