import {loadScript} from '../../utils';
import {getSalesforceLanguage} from './getSalesforceLanguage';
import type {InitParams, SalesForceBotApi} from './types';

export type InitBotProps = {
  init: InitParams;
  bootstrapJsSrc: string;
  locale: string;
  devMode?: boolean;
};

let initialised = false;
export const isBotInitialised = () => initialised;

export const initBot = async (
  props: InitBotProps
): Promise<SalesForceBotApi> => {
  // send back the same instance each time otherwise we get a load of
  // duplicate iframe embeds
  if (isBotInitialised()) {
    return window.embeddedservice_bootstrap;
  }

  return new Promise((resolve, reject) => {
    const timeout = setTimeout(() => {
      reject(new Error('Chat initialisation timed out'));
    }, 10000);

    loadScript({src: props.bootstrapJsSrc})
      .then(() => {
        window.embeddedservice_bootstrap.settings.devMode =
          props.devMode ?? false;
        window.embeddedservice_bootstrap.settings.language =
          getSalesforceLanguage(props.locale);
        window.embeddedservice_bootstrap.settings.hideChatButtonOnLoad = true;
        window.embeddedservice_bootstrap.init(
          props.init.orgId,
          props.init.eswConfigDevName,
          props.init.siteUrl,
          {scrt2URL: props.init.scrt2URL}
        );

        /**
         * We can only use the embedded service API once the onEmbeddedMessagingReady event has been fired
         * so we wait until that happens before resolving the promise with the API
         */
        window.addEventListener(
          'onEmbeddedMessagingReady',
          () => {
            clearTimeout(timeout);
            initialised = true;
            resolve(window.embeddedservice_bootstrap);
          },
          {once: true}
        );
      })
      .catch(reject) // By rejecting the promise here, the error could be caught by the caller
      .finally(() => {
        clearTimeout(timeout);
      });
  });
};
